
export const environment = {
  otm: "tdtukokand",
  production: true,
  http_auth: "EIFMNEMWFE93FNf",
  otm_name: "TDTU Qo‘qon filiali ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig:{ 
    apiKey: "AIzaSyC1ktqlK-zy1s1uf9KmXA3K6WJg-byN6fo",
    authDomain: "tdtukokand-9264d.firebaseapp.com",
    projectId: "tdtukokand-9264d",
    storageBucket: "tdtukokand-9264d.appspot.com",
    messagingSenderId: "1060781214963",
    appId: "1:1060781214963:web:dbca7ee122f102d5d37b86",
    measurementId: "G-RN64626WH5"
  },
  http: {
    socket:{
      path: 'wss://ferpi.tsnqb.uz/socket.io' 
    },
    editDepartment: {
      path: "https://tdtukokand.tsnqb.uz/api/system/department",
    },
    getDepartmentAArea: {
      path: "https://tdtukokand.tsnqb.uz/api/system/getAll",
    },
    addDepartment: {
      path: "https://tdtukokand.tsnqb.uz/api/system/department",
    },
    editArea: {
      path: "https://tdtukokand.tsnqb.uz/api/system/area",
    },
    addArea: {
      path: "https://tdtukokand.tsnqb.uz/api/system/area",
    },
    deleteArea: {
      path: "https://tdtukokand.tsnqb.uz/api/system/area",
    },
    removeDepartment: {
      path: "https://tdtukokand.tsnqb.uz/api/system/department",
    },
    userRegister:{
      path: 'https://tdtukokand.tsnqb.uz/api/auth/registration'
    },
    recoverPass:{
      path: 'https://tdtukokand.tsnqb.uz/api/mailing/send-mail'
    },
    allRoles: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    removeRole: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    setRole: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    removeTeacher: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    allUsers: {
      path: 'https://tdtukokand.tsnqb.uz/api/users/all'
    },
    updateUser: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    deleteUser: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    login: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/login'
    },
    autologin: {
      path: 'https://tdtukokand.tsnqb.uz/api/auth/autologin'
    },
    get: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/get_data.php",
       file: "pvorey.txt"
    },
    getFieldsInform: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/get_fields_inform.php"
    },
    delete_only_inform_file: {
      path: "https://tdtukokand.tsnqb.uz/db/2023/delete_only_inform_file.php"
    },
    setInc: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/set_data_inc.php",
       file: "pvorey.txt"
    },
    del: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/del_data.php",
       file: "pvorey.txt"
    },
    setIncDelUpd: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/set_inc_del_upd.php",
       file: "pvorey.txt"
    },
    remDocDownGrade: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/rem_doc_with_down_grade.php",
    },
    googleScholarCited: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/google_scholar.php",
    },
    googleScholarCitedAll: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/google_scholar_all.php",
    },
    setNewPvo: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/set_new_pvo.php",
    },
    removePvo: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/remove_pvo.php",
    },
    controlIndexStatus: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/control_index_status.php",
    },
    createAnnounce: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/create_announce.php",
    },
    getAnnounces: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/get_announces.php",
    },
    removeAnnounce: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/remove_announce.php",
    },
    editUserAccaunt: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/edit_user_account.php",
    },
    createIndex: {
       path: "https://tdtukokand.tsnqb.uz/db/2023/create_index.php",
    }
  },
  tizim: [
    {
      fakultet: "Energetika",
      kafedralar: [
         "Elektr texnologiyalari",
         "Ijtimoiy va gumanitar fanlar",
      ]
    },
    {
      fakultet: "Muhandislik",
      kafedralar: [
         "Muhandislik texnologiyalari",
         "Kimyoviy texnologiyalari",
         "Materialshunoslik va aniq fanlar",
      ]
    }
  ],
  grastud: 556,
  fakultet: ["Energetika", "Muhandislik"],
  kafedra: [ 
    "Elektr texnologiyalari",
    "Ijtimoiy va gumanitar fanlar",
    "Muhandislik texnologiyalari",
    "Kimyoviy texnologiyalari",
    "Materialshunoslik va aniq fanlar",
  ]
};
